<template>
  <div class="">
    <ControlNavbar />
    <div class="container-fluid">
      <br />
      <div class="category row align-items-center">
        <div
          class="col-3 col-sm-2 col-md-2 category-title"
          @click="$router.go(-1)"
        >
          <p>
            <img :src="parent.iconUrl" :alt="parent.icon" width="60px" />
          </p>
          <p>{{ parent.label }}</p>
        </div>
        <div class="col-9 col-sm-10 col-md-10">
          <div class="items">
            <slick ref="slick" :options="slickOptions">
              <div
                class=""
                v-for="(item, index) in parent.data.items"
                :key="index"
              >
                <div
                  class="item"
                  @click="
                    (e) => {
                      updateListingPreviewData(item, e);
                    }
                  "
                >
                  <p>
                    <img
                      :src="item.iconUrl"
                      :alt="item.icon"
                      style="width: 70px"
                    />
                  </p>
                  <p style="font-size: 13px">{{ item.label }}</p>

                  <!-- <router-link
                    v-if="item.type === 'voucher'"
                    :to="{
                      name: 'Voucher',
                      params: { voucher: item },
                    }"
                  >
                    <img
                      :src="item.iconUrl"
                      :alt="item.icon"
                      style="width: 70px"
                    />
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </router-link>
                  <router-link
                    v-if="item.type !== 'voucher'"
                    :to="{
                      name: 'SpecificCategory',
                      params: { category: item },
                    }"
                  >
                    <img
                      :src="item.iconUrl"
                      :alt="item.icon"
                      style="width: 70px"
                    />
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </router-link> -->
                </div>
              </div>
            </slick>
          </div>
        </div>

        <!-- <div class="col-3 col-sm-2 col-md-2 category-title">
          <p>
            <img :src="category.iconUrl" :alt="category.icon" width="60px" />
          </p>
          <p>{{ category.label }}</p>
        </div>
        <div class="col-9 col-sm-10 col-md-10">
          <div class="items">
            <slick ref="slick" :options="slickOptions">
              <div v-for="(item, index) in category.data.items" :key="index">
                <div class="item">
                  <router-link
                    v-if="item.type === 'voucher'"
                    :to="{
                      name: 'Voucher',
                      params: { voucher: item },
                    }"
                  >
                    <img
                      :src="item.data.image"
                      :alt="item.icon"
                      style="width: 99%"
                    />
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </router-link>
                  <router-link
                    v-if="item.type === 'listing'"
                    :to="{
                      name: 'SpecificListing',
                      params: { category: item },
                    }"
                  >
                    <img
                      :src="item.iconUrl"
                      :alt="item.icon"
                      style="width: 70px"
                    />
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </router-link>
                  <router-link
                    v-if="item.type === 'category'"
                    :to="{
                      name: 'SpecificCategory',
                      params: { category: item },
                    }"
                  >
                    <img
                      :src="item.iconUrl"
                      :alt="item.icon"
                      style="width: 70px"
                    />
                    <p style="font-size: 13px">{{ item.label }}</p>
                  </router-link>
                </div>
              </div>
            </slick>
          </div>
        </div> -->
      </div>
      <div class="row">
        <!-- item itself -->
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
// import axios from "axios";
import Slick from "vue-slick";

// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
export default {
  name: "SpecificCategory",
  components: { ControlNavbar, Slick },
  data() {
    return {
      slickOptions: {
        rtl: this.$session.get("direction") == "rtl" ? true : false,
        infinite: false,
        arrows: false,
        paddingLeft: "0px",
        paddingRight: "0px",
        marginRight: "0px",
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      },
      category: this.$route.params.category,
      parent: this.$route.params.parent,
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  created() {
    console.log("category: ", this.$route.params.category);
  },
};
</script>

<style lang="scss" scoped>
.slick-slide img {
  display: inherit;
}
.slick-slide img {
  display: inherit;
}
.category-title {
  padding-top: 10px;
  background: #e62946;
  border-radius: 0px 45px 45px 0px;
  color: white;
}
.category {
  box-shadow: 0px 7px 5px grey;
}
.item {
  text-align: -webkit-center;
}
</style>
